import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step certificate bundle`}</strong>{` -- bundle a certificate with intermediate certificate(s) needed for certificate path validation`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step certificate bundle <crt-file> <ca> <bundle-file>
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step certificate bundle`}</strong>{` bundles a certificate
with any intermediates necessary to validate the certificate.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`crt-file`}</inlineCode>{`
The path to a leaf certificate to bundle with issuing certificate(s).`}</p>
    <p><inlineCode parentName="p">{`ca`}</inlineCode>{`
The path to the Certificate Authority issuing certificate.`}</p>
    <p><inlineCode parentName="p">{`bundle-file`}</inlineCode>{`
The path to write the bundle.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`-f`}</strong>{`, `}<strong parentName="p">{`--force`}</strong>{`
Force the overwrite of files without asking.`}</p>
    <h2>{`Exit codes`}</h2>
    <p>{`This command returns 0 on success and >0 if any error occurs.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Bundle a certificate with the intermediate certificate authority (issuer):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate bundle foo.crt intermediate-ca.crt foo-bundle.crt
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      